<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box ">
          <h4 class="page-title">Edit Assessment Form</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="name">
                    Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.name.$error,
                    }"
                    v-model="name"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.name.$errors,
                      value: 'Name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="remark">
                    Status
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <v-select
                    :options="['Active', 'Inactive']"
                    :clearable="false"
                    :class="{
                      'p-invalid': this.v$.status.$error,
                    }"
                    v-model="status"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.status.$errors,
                      value: 'Status',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row mb-3" v-show="status == 'Inactive'">
                <div class="col-lg-3">
                  <label for="remark">
                    Remark
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="percentage"
                    id="percentage"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.remark.$error,
                    }"
                    v-model="remark"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.remark.$errors,
                      value: 'Remark',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="mt-3 mb-3 ms-3 text-center">
                    <router-link :to="{ name: 'assessment-form' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>

                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="store()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    vSelect,
  },
  mixins: [validationErrors],

  data() {
    return {
      name: "",
      status: "Active",
      remark: "",
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
        name: { required },
        status: { required},
        remark: this.status == "Inactive" ? { required } : "",
    };
  },

  methods: {
    async getAssessmentFormById() {
      await axios
        .get(
          `${this.baseUrl}admin/v2/assessment-form/${this.$route.params.id}`
        )
        .then((res) => {
          this.name = res?.data?.data?.name
          this.status = res?.data?.data?.status == 1 ? "Active" : "Inactive"
          this.remark = res?.data?.data?.remark
        });
    },

    async store() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        return;
      }
      this.isLoading = true;
      if (this.status == "Active") this.remark = "";

      let sendData = { 
        name: this.name,
        status: this.status === "Active" ? 1 : 0,
        remark: this.remark
       };

      await axios
        .put(`${this.baseUrl}admin/v2/assessment-form/`+ this.$route.params.id , sendData)
        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "assessment-form" });
          this.toast.success("Successfully update Assessment Form!");
        })
        .catch(() => {
          this.isLoading = false;
          this.toast.error("Something Went Wrong!");
        });
    },
  },
  async created() {
    await this.getAssessmentFormById()
  },

};
</script>
